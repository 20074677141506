// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import Navbar from './components/Navbar';
import Bookshelf from './components/Bookshelf';
import Posts from './components/Posts';
import './App.css';

function App() {
    return (
        <Router>
            <div className="App">
                <Navbar />
                <div className="container">
                  <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/bookshelf" element={<Bookshelf />} />
                    <Route path="/posts" element={<Posts />} />
                  </Routes>
                </div>
            </div>
        </Router>
    );
}

export default App;

