// src/components/Posts.js
import React from 'react';

function Posts() {
    return (
        <div>
            <h2>My Posts</h2>
            <p>Read my latest blog posts.</p>
        </div>
    );
}

export default Posts;
