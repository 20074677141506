import React from 'react';
import './Home.css'; // Ensure you import your CSS file

function Home() {
    return (
        <div className="home-container">
            <h2 className="home-text">Hi, I'm Matt.</h2>
            <p className="home-text">
                I am a programmer.<br /><br />
                I think a lot about the future of the internet, tools for thought, how brains differ from LLMs, and the nature of representation. <br /><br />
                Until recently, I was a Ph.D. student at the University of Minnesota, where I did neuroscience research. Most of my work was done in collaboration with UC Berkeley neuroscientist Terrence Deacon. Recently, I left the Ph.D. to pursue AI startups. <br /><br />
                Take a look around this website if you want a glimpse into what I have been reading or thinking about.<br /><br />
                If you are interested in what you find, please get in touch. <br /><br />
            </p>
        </div>
    );
}

export default Home;
