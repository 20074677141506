// src/components/Bookshelf.js
import React from 'react';

function Bookshelf() {
    return (
        <div>
            <h2>My Bookshelf</h2>
            <p>Here are some of my favorite books.</p>
        </div>
    );
}

export default Bookshelf;
